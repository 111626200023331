import axios from "axios";
import { getToken } from "./auth";
import { withRouter } from "react-router-dom";

const api = axios.create({
  // baseURL: "http://localhost:5001/rotas-297b8/us-central1", // Local
  baseURL: "https://us-central1-rotas-297b8.cloudfunctions.net", // Prodution
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default api;
