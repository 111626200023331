import React from 'react'

import { useEffect, useState } from 'react';
import MaskLoading from '../../components/maskLoading';
import Pagination from '../../components/pagination/pagination';
import { Firestore } from '../../services/firebase';
import { confirmAlert } from 'react-confirm-alert';

import { FaEdit } from "react-icons/fa";

import './style.scss';
interface data {
  id?: string;
  cityId?: string;
  external_id?: string;
  name : string,
  UF? : string
}
type valuesStates = data[];
type valuesCities = data[];
type valuesRotes = data[];

const form = {
  name    : '',
  UF      : ''
}

const dataqueryStates = Firestore.collection('states').orderBy('name', 'asc')
const dataqueryCities = Firestore.collection('cities').orderBy('name', 'asc')
const dataqueryRotes = Firestore.collection('rotes').orderBy('name', 'asc')

export default function HomeRotes() {
  const [loading, setLoading] = useState(true);

  const [states, setStates] = useState<valuesStates>([]);
  const [selectedUF, setSelectedUf] = useState('');
  const [errorState, setErrorState] = useState(false);
  const [showAddState, setShowAddState] = useState(false);
  const [formState, setFormState] = useState<data>(form);

  const [cities, setCities] = useState<valuesCities>([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [errorCity, setErrorCity] = useState(false);
  const [showAddCity, setShowAddCity] = useState(false);
  const [formCity, setFormCity] = useState<data>(form);

  const [rotes, setRotes] = useState<valuesRotes>([]);
  const [errorRote, setErrorRote] = useState(false);
  const [showAddRote, setShowAddRote] = useState(false);
  const [formRote, setFormRote] = useState<data>(form);

  useEffect(() => {
      getData();
  }, []);

  async function getData() {
    setStates([]);
    setSelectedUf('');
    setCities([]);
    setSelectedCity('');
    setRotes([]);
    setLoading(true);
    setErrorState(false);
    const result = await dataqueryStates.get();

    if (result?.docs?.length) {
      const registro: valuesStates = [];
      result.docs.forEach((element: any) => {
        const save = { id: element.id, ...element.data() }
        registro.push(save);
      });
      setStates(registro);
    } else {
      setStates([]);
      setErrorState(true);
    }
    setLoading(false);
  }

  function addState() {
    setFormState(form);
    setShowAddState(true);
  }
  async function saveState() {
    setLoading(true);
    if( formState.id ) {
      await Firestore.collection('states').doc(formState.id).update(formState).then(response => {
        setFormState(form);
        getData();
        selectState(form.UF);
        setShowAddState(false);
      }).catch(erro => {
        setErrorState(true);
      });
    } else {
      await Firestore.collection('states').add(formState).then(response => {
        setFormState(form);
        getData();
        selectState(form.UF);
        setShowAddState(false);
      }).catch(erro => {
        setErrorState(true);
      });
    }
  }

  async function selectState(stateUF : string) {
    setLoading(true);
    setErrorCity(false);
    setSelectedUf(stateUF);
    setCities([]);
    setSelectedCity('');
    setRotes([]);
    const result = await dataqueryCities
      .where('UF', '==', stateUF)
      .get();

    if (result?.docs?.length) {
      const registro: valuesStates = [];
      result.docs.forEach((element: any) => {
        const save = { id: element.id, ...element.data() }
        registro.push(save);
      });
      setCities(registro);
    } else {
      setErrorCity(true);
      setCities([])
    }
    setLoading(false);
  }

  function addCity() {
    setFormCity(form);
    setFormCity({ ...formCity, UF: selectedUF });
    setShowAddCity(true);
  }
  async function saveCity() {
    setLoading(true);
    if( formCity.id ){
      let id = formCity.id;
      await Firestore.collection('cities').doc(formCity.id).update(formCity).then(response => {
        setFormCity(form);
        selectState(selectedUF);
        selectCity(id);
        setShowAddCity(false);
      }).catch(erro => {
        setErrorState(true);
      });
    } else {
      setFormCity({ ...formCity, UF: selectedUF });
      await Firestore.collection('cities').add(formCity).then(response => {
        formCity.id = response.id;
        Firestore.collection('cities').doc(formCity.id).update(formCity);
        setFormCity(form);
        selectState(selectedUF);
        selectCity(response.id);
        setShowAddCity(false);
      }).catch(erro => {
        setErrorState(true);
      });
    }
  }

  async function selectCity(cityId : string) {
    setLoading(true);
    setErrorCity(false);
    setErrorRote(false);
    setSelectedCity(cityId);
    setRotes([]);
    const result = await dataqueryRotes
      .where('cityId', '==', cityId)
      .get();

    if (result?.docs?.length) {
      const registro: valuesRotes = [];
      result.docs.forEach((element: any) => {
        const save = { id: element.id, ...element.data() }
        registro.push(save);
      });
      setRotes(registro);
    } else {
      setErrorRote(true);
      setRotes([])
    }
    setLoading(false);
  }

  function addRote() {
    setFormRote(form);
    setFormRote({ ...formRote, cityId: selectedCity });
    setShowAddRote(true);
  }
  async function saveRote() {
    setLoading(true);
    if( formRote.id ) {
      await Firestore.collection('rotes').doc(formRote.id).update(formRote).then(response => {
        setFormRote(form);
        selectCity(selectedCity);
        setShowAddRote(false);
      }).catch(erro => {
        setErrorState(true);
      });
    } else {
      setFormRote({ ...formRote, cityId: selectedCity });
      await Firestore.collection('rotes').add(formRote).then(response => {
        setFormRote(form);
        selectCity(selectedCity);
        setShowAddRote(false);
      }).catch(erro => {
        setErrorState(true);
      });
    }
  }

  function confirmDelete(id: string, collection: string){
    confirmAlert({
      title: 'Remover item',
      message: 'Tem certeza que deseja remover este item?',
      overlayClassName: "overlay-custom-danger",
      buttons: [
        {
          label: 'Sim',
          className: 'btn-danger',
          onClick: () => remove(id, collection)
        },
        {
          label: 'Não',
          className: 'btn-primary',
          onClick: () => console.log('cancel')
        }
      ]
    });
  };

  function edit(id: string, collection: string) {
    switch(collection){
      case 'states':
        let state = states.find((state) => {
          return state.id === id;
        });
        if( state ){
          setFormState(state);
          setShowAddState(true);
        }
      break;
      case 'cities':
        let city = cities.find((city) => {
          return city.id === id;
        });
        if( city ){
          setFormCity(city);
          setShowAddCity(true);
        }
      break;
      case 'rotes':
        let rote = rotes.find((rote) => {
          return rote.id === id;
        });
        if( rote ){
          setFormRote(rote);
          setShowAddRote(true);
        }
      break;
    }
  }

  async function remove(uid: string, collection: string) {
    setLoading(true);
    Firestore.collection(collection).doc(uid).delete().then(() => {
      switch(collection){
        case 'states':
          getData();
        break;
        case 'cities':
          selectState(selectedUF);
        break;
        case 'rotes':
          selectCity(selectedCity);
        break;
      }

      confirmAlert({
        title: 'Item removido com sucesso',
        buttons: [{
            label: 'Ok',
            onClick: () => console.log('removido')
          }]
      });
    }).catch((error) => {
      confirmAlert({
        title: 'Erro ao remover item',
        buttons: [
          {
            label: 'Cancelar',
            onClick: () => console.log('removido')
          },
          {
            label: 'Tentar novamente',
            onClick: () => remove(uid, collection)
          }
        ]
      });
    });
    setLoading(false);
  }

  return (
    <section className="page-rotes">
      {loading && <MaskLoading />}
      <div className="card">
        <div className="row">
          <div className="third-width">
            <table className="table">
              <thead>
                <tr>
                  <th>Estados</th>
                </tr>
              </thead>
              <tbody>
                {!showAddState ?
                  <tr className="lineButton" >
                    <th><button onClick={() =>  addState()}>Adicionar</button></th>
                  </tr>
                :
                  <tr>
                    <th>
                      <div className="card">
                        <div className="formGroup formGroup-Block-2">
                          <label htmlFor="user">Nome</label>
                          <input
                            type="text"
                            value={formState.name}
                            onChange={(val) => setFormState({ ...formState, name: val.target.value })}
                            className="user" />
                        </div>
                        <div className="formGroup formGroup-Block-2">
                          <label htmlFor="user">UF</label>
                          <input
                            type="text"
                            value={formState.UF}
                            onChange={(val) => setFormState({ ...formState, UF: val.target.value })}
                            className="user"
                          />
                          <button type="button" onClick={() =>  saveState()}>
                            { formState.id ? 'Atualizar' : 'Salvar' }
                          </button>
                        </div>
                      </div>
                    </th>
                  </tr>
                }

                {
                  !errorState ?
                    states.map((val: any) =>
                      <tr key={val.id}>
                        <td className={selectedUF == val.UF ? 'trSelected' : ''} onClick={() =>  selectState(val.UF)}>
                          <span>{val.name} - {val.UF}</span>

                          <span className="icon-right" onClick={() => confirmDelete(val.id, 'states')}>&times;</span>
                          <span className="icon-right" onClick={() => edit(val.id, 'states')}><FaEdit /></span>
                        </td>
                      </tr>
                    )
                    : <tr> <td colSpan={20}> <div className='msg-error'>Erro ao buscar registros</div> </td></tr>
                }
              </tbody>
            </table>
          </div>
          <div className="third-width">
            <table className="table">
              <thead>
                <tr>
                  <th>Municipio</th>
                </tr>
              </thead>
              <tbody>

                {selectedUF ?
                  !showAddCity ?
                    <tr className="lineButton" >
                      <th><button onClick={() =>  addCity()}>Adicionar</button></th>
                    </tr>
                  :
                    <tr>
                      <th>
                        <div className="card">
                          <div className="formGroup formGroup-Block-2">
                            <label htmlFor="user">Nome</label>
                            <input
                              type="text"
                              value={formCity.name}
                              onChange={(val) => setFormCity({ ...formCity, name: val.target.value })}
                              className="user"
                            />
                          </div>
                          <div className="formGroup formGroup-Block-2">
                            <label htmlFor="user">ID Orgiem</label>
                            <input
                              type="text"
                              value={formCity.external_id}
                              onChange={(val) => setFormCity({ ...formCity, external_id: val.target.value })}
                              className="user"
                            />
                            <button type="button" onClick={() =>  saveCity()}>
                              { formCity.id ? 'Atualizar' : 'Salvar' }
                            </button>
                          </div>
                        </div>
                      </th>
                    </tr>
                  :
                  <tr> <td colSpan={20}> <div className='msg-info'>Selecione um Estado</div> </td></tr>
                }
                {
                  !errorCity ?
                    cities.map((val: any) =>
                      <tr key={val.id}>
                        <td className={selectedCity == val.id ? 'trSelected' : ''} onClick={() =>  selectCity(val.id)}>
                          {val.name}
                          <span className="icon-right" onClick={() => confirmDelete(val.id, 'cities')}>&times;</span>
                          <span className="icon-right" onClick={() => edit(val.id, 'cities')}><FaEdit /></span>
                        </td>
                      </tr>
                    )
                    : <tr> <td colSpan={20}> <div className='msg-error'>Erro ao buscar registros</div> </td></tr>
                }
              </tbody>
            </table>
          </div>
          <div className="third-width">
            <table className="table">
              <thead>
                <tr>
                  <th>Destino</th>
                </tr>
              </thead>
              <tbody>
                {selectedCity ?
                  !showAddRote ?
                    <tr className="lineButton" >
                      <th><button onClick={() =>  addRote()}>Adicionar</button></th>
                    </tr>
                  :
                    <tr>
                      <th>
                        <div className="card">
                          <div className="formGroup formGroup-Block-2">
                            <label htmlFor="user">Nome</label>
                            <input
                              type="text"
                              value={formRote.name}
                              onChange={(val) => setFormRote({ ...formRote, name: val.target.value })}
                              className="user"
                            />
                          </div>
                          <div className="formGroup formGroup-Block-2">
                            <label htmlFor="user">ID Destino</label>
                            <input
                              type="text"
                              value={formRote.external_id}
                              onChange={(val) => setFormRote({ ...formRote, external_id: val.target.value })}
                              className="user"
                            />
                          </div>
                          <div className="formGroup formGroup-Block-2">
                            <label htmlFor="user">UF</label>
                            <input
                              type="text"
                              value={formRote.UF}
                              onChange={(val) => setFormRote({ ...formRote, UF: val.target.value })}
                              className="user"
                            />
                            <button type="button" onClick={() =>  saveRote()}>
                              { formRote.id ? 'Atualizar' : 'Salvar' }
                            </button>
                         </div>
                        </div>
                      </th>
                    </tr>
                  :
                  <tr> <td colSpan={20}> <div className='msg-info'>Selecione um Municipio</div> </td></tr>
                }
                {
                  !errorRote ?
                    rotes.map((val: any) =>
                      <tr key={val.id}>
                        <td>
                          {val.name} - {val.UF}
                          <span className="icon-right" onClick={() =>  confirmDelete(val.id, 'rotes')}>&times;</span>
                          <span className="icon-right" onClick={() => edit(val.id, 'rotes')}><FaEdit /></span>
                        </td>
                      </tr>
                    )
                    : <tr> <td colSpan={20}> <div className='msg-error'>Erro ao buscar registros</div> </td></tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  )
}
