import React from 'react'

import { NavLink } from "react-router-dom";
import './style.scss';

export default function Menu() {
  return (
    <div className="mainMenu">
      <ul>
        <li>
          <NavLink activeClassName="active" exact to="/" >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/homeRotes" >
            Rotas
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/client" >
            Clientes
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/configPage" >
            Configurações da Página
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/purchase" >
            Vendas
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/payload" >
            Financeiro
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
