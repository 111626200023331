import React from 'react'

import { useEffect, useState } from 'react';
import './style.scss';

import { ReactComponent as LoadingButton } from '../../assets/loading.svg';
import { Storage, Firestore } from '../../services/firebase';

interface offerInteface {
  id       : string,
  position : number,
  cityId? : string,
  roteId? : string,
  price? : string,
  from_UF? : string,
  to_UF? : string,
  from_name? : string,
  to_name? : string,
  url? : string
}

const inicialForm = {
  id        : '',
  position  : 0,
  cityId    : '',
  roteId    : '',
  prince    : '',
  from_UF   : '',
  to_UF     : '',
  from_name : '',
  to_name   : ''
}

interface baseData {
  id?: string;
  cityId?: string;
  name : string,
  UF : string
}
type valuesStates = baseData[];
type valuesCities = baseData[];
type valuesRotes  = baseData[];



const dataqueryStates = Firestore.collection('states').orderBy('name', 'asc')
const dataqueryCities = Firestore.collection('cities').orderBy('name', 'asc')
const dataqueryRotes = Firestore.collection('rotes').orderBy('name', 'asc')

export default function CrudTestAdd(props: any) {
  const [form, setForm] = useState<offerInteface>(inicialForm)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [success, setSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState('');

  const [states, setStates] = useState<valuesStates>([]);
  const [cities, setCities] = useState<valuesCities>([]);
  const [rotes, setRotes] = useState<valuesRotes>([]);

  const [inputImage, setInputImage] = useState<any>(null);

  useEffect(() => {
      setForm(props.data);
      getData();
  },[]);

  async function getData() {  
    await setLoading(true);
    const result = await dataqueryStates.get();
    if (result?.docs?.length) {
      const registro: valuesStates = [];
      await result.docs.forEach((element: any) => {
        const save = { id: element.id, ...element.data() }
        registro.push(save);
      });
      await setStates(registro);
    }
    if( props?.data?.cityId ){
      await Firestore.collection('cities').doc(props.data.cityId).get()
      .then(async (response) => {
        let city = response.data();
        if( city ){
          await loadCities(city.UF);
          await loadRotes(city.id);
        }
      });
    }
    await setLoading(false);
  }

  async function salvar(e: any) {
    e.preventDefault();
    setLoading(true);
    if( inputImage ){
      await uploadImage();
    }
    await Firestore.collection('offers').doc(form.id).update(form).then(response => {
      setSuccess(true);
      setForm(inicialForm);
      setMsgSuccess('Item foi editado com sucesso');
      props.back(false);
    }).catch(erro => {
      setError(true);
      setMsgError('Algo deu errado');
    });
    
    setLoading(false);
  }

  async function uploadImage(){
    const oldImage = await Storage.ref('offer_images').child(form.id+'.jpeg').listAll();
    if( oldImage.items.length > 0 )
      await Storage.ref('offer_images').child(form.id+'.jpeg').delete();

    await Storage.ref('offer_images').child(form.id+'.jpeg').put(inputImage);
  }

  async function selectState(stateUF : string) {
    await setLoading(true);
    await setForm({ ...form, from_UF: stateUF });
    await setCities([]);
    await setRotes([]);
    await loadCities(stateUF);
    await setLoading(false);
  }

  async function loadCities(stateUF : string) {
    await setLoading(true);
    const result = await dataqueryCities
      .where('UF', '==', stateUF)
      .get();

    if (result?.docs?.length) {
      const registro: valuesStates = [];
      result.docs.forEach((element: any) => {
        const save = { id: element.id, ...element.data() }
        registro.push(save);
      });
      await setCities(registro);
    } else {
      await setCities([])
    }
    await setLoading(false);
  }

  async function selectCity(cityId : string) {    
    let city = await cities.find(item => item.id === cityId);
    if( city ){
      await setLoading(true);
      await setForm({ ...form, cityId: city.id, from_name: city.name+' - '+city.UF });
      await setRotes([]);
      await loadRotes(cityId);     
      await setLoading(false);
    }
  }

  async function loadRotes(cityId : string) {    
    await setLoading(true);
    const result = await dataqueryRotes
    .where('cityId', '==', cityId)
    .get();

    if (result?.docs?.length) {
      const registro: valuesRotes = [];
      result.docs.forEach((element: any) => {
        const save = { id: element.id, ...element.data() }
        registro.push(save);
      });
      await setRotes(registro);
    } else {
      await setRotes([]);
    }
    await setLoading(false);
  }

  async function selectRote(roteId : string) {
    let rote = rotes.find(item => item.id === roteId);
    if( rote )
      setForm({ ...form, roteId: rote.id, to_name: rote.name+' - '+rote.UF });

    return true;
  }

  async function upload(image : any) {
    if(image.length > 0)
      image = image[0];
    setInputImage(image);
  }

  return (
    <section className="page-crud">
      <div className="actions actions-right">
        <button onClick={() => props.back(false)}>Voltar</button>
      </div>
      <div className="card">
        <form action="" className="formGroup-Block-2" onSubmit={salvar}>
          <div className="formGroup formGroup-Block-2">
            <label>UF Origem</label>
            <select value={form.from_UF} onChange={(val) => selectState(val.target.value) }>
              <option key={'0'}>Selecione</option>
              {states.map((state: any) =>
                <option key={state.id} value={state.UF}>{state.name} - {state.UF}</option>
              )}
            </select>
          </div>
          <div className="formGroup formGroup-Block-2">
            <label htmlFor="user">Cidade Origem</label>
            <select value={form.cityId} onChange={(val) => selectCity(val.target.value) }>
              <option key={'0'}>Selecione</option>
              {cities.map((city: any) =>
                <option key={city.id} value={city.id}>{city.name}</option>
              )}
            </select>
          </div>
          <div className="formGroup formGroup-Block-2">
            <label htmlFor="user">Destino</label>
            <select value={form.roteId} onChange={(val) => selectRote(val.target.value)}>
              <option key={'0'}>Selecione</option>
              {rotes.map((rote: any) =>
                <option key={rote.id} value={rote.id}>{rote.name} - {rote.UF}</option>
              )}
            </select>
          </div>
          <div className="formGroup formGroup-Block-2">
            <label htmlFor="user">Preço</label>
            <input
              type="text"
              value={form.price}
              onChange={(val) => setForm({ ...form, price: val.target.value })}
              className="user"
            />
          </div>
          <div className="formGroup formGroup-Block-2">
            <label htmlFor="user">URL </label>
            <input type='text' id='file' value={form.url} onChange={(val) => setForm({ ...form, url: val.target.value })}/>
          </div>
          <div className="formGroup formGroup-Block-2">
            <label htmlFor="user">Imagem</label>
            <input type='file' id='file' onChange={(val) => upload(val.target.files)}/>
          </div>

          
          {error && <div className='msg-error'>{msgError}</div>}
          {success && !error && <div className='msg-success'>{msgSuccess}</div>}
          <div className="actions ">
            <button type="submit">{loading ? <LoadingButton /> : 'Salvar'}</button>
          </div>
        </form>
      </div>
    </section>
  )
}
