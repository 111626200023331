import React from 'react'

import { useEffect, useState } from 'react';
import MaskLoading from '../../components/maskLoading';
import Pagination from '../../components/pagination/pagination';
import { Firestore } from '../../services/firebase';
import { confirmAlert } from 'react-confirm-alert';
import FunctionsService from "../../services/functions";
import moment from 'moment';

const initalFilter = {
  startDate : moment().startOf('month').format('YYYY-MM-DD'),
  endDate   : moment().endOf('month').format('YYYY-MM-DD'),
}

const initalTotals = {
    available : {
        amount : 0
    },
    waiting_funds : {
        amount : 0
    }
}

export default function Payload() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [data, setData] = useState<any>([]);
    const [totals, setTotals] = useState<any>(initalTotals);
    const [filter, setFilter] = useState<any>(initalFilter);

    useEffect(() => {
        setData([]);
        getData();
        getBalance();
    }, []);

    async function getBalance() {
        const result = await FunctionsService.balance();
        console.log(result);

        if( result.object && result.object == 'balance' )
            setTotals(result);
    }

    async function getData() {
        setLoading(true);
        setError(false);
        const result = await FunctionsService.getPayload(filter);
        if( result.list && result.list.length > 0 ){
            setData(result);
        } else {
            setError(true);
        }

        setLoading(false);
    }

    useEffect(() => {
        setData([]);
        getData();
    }, [filter]);

    return (
        <section className="page-crud">
            {loading && <MaskLoading />}
                <>
                    <div className="card">
                        <h1>Saldo</h1>
                         <div className="form formGroup-Block-2">
                            <div className="formGroup formGroup-Block-2">
                                <label>Saldo disponível</label>
                                <input type="number" value={(totals.available.amount/100).toFixed(2)}  disabled />
                            </div>
                            <div className="formGroup formGroup-Block-2">
                                <label>A receber</label>
                                <input type="number" value={(totals.waiting_funds.amount/100).toFixed(2)}  disabled />
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <h1>Extrato</h1>
                        <div className="form formGroup-Block-2">
                            <div className="formGroup formGroup-Block-2">
                                <label>De</label>
                                <input type="date" value={filter.startDate}  onChange={(val) => setFilter({ ...filter, startDate: val.target.value})} />
                            </div>
                            <div className="formGroup formGroup-Block-2">
                                <label>Até</label>
                                <input type="date" value={filter.endDate}  onChange={(val) => setFilter({ ...filter, endDate: val.target.value})} />
                            </div>
                        </div>

                        <div className="form formGroup-Block-2">
                            <div className="formGroup formGroup-Block-2">
                                <label>Total Bruto R$</label>
                                <input type="number" value={data.value}  disabled />
                            </div>
                            <div className="formGroup formGroup-Block-2">
                                <label>Total Liquido R$</label>
                                <input type="number" value={data.total}  disabled />
                            </div>
                        </div>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Tipo</th>
                                    <th>Transação</th>
                                    <th>Data da transação</th>
                                    <th>Data da pagamento</th>
                                    <th>Valor Bruto</th>
                                    <th>Valor Liquido</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                !error ?
                                    data.list ? data.list.map((val: any) =>
                                        <tr>
                                            <td>
                                                {val.type == 'credit' ? 'Credito' : ''}
                                                {val.type == 'refund' ? 'Estorno' : ''}
                                            </td>
                                            <td>{val.transaction_id}</td>
                                            <td>{moment(val.date_created).format('DD/MM/yyyy')}</td>
                                            <td>{moment(val.payment_date).format('DD/MM/yyyy')}</td>
                                            <td>{(val.amount/100).toFixed(2)}</td>
                                            <td>{((val.amount-val.fee)/100).toFixed(2)}</td>
                                        </tr>
                                    ) : <tr> <td colSpan={20}> <div className='msg-error'>Erro ao buscar registros</div> </td></tr>
                                : <tr> <td colSpan={20}> <div className='msg-error'>Erro ao buscar registros</div> </td></tr>
                            }
                            </tbody>
                        </table>
                    </div>
                </>
        </section>
    )
}
