import React from 'react'

import { useEffect, useState } from 'react';
import MaskLoading from '../../components/maskLoading';
import Pagination from '../../components/pagination/pagination';
import { Firestore } from '../../services/firebase';
import { confirmAlert } from 'react-confirm-alert';

import './style.scss';

interface dataCrud {
  id?: string,
  email?: string,
  endereco?: string,
  password?: string,
  phone?: string,
}
type valuesCrud = dataCrud[];

const limit = 6;
const dataquery = Firestore.collection('users').where('profile', '==', 'cliente').orderBy('name', 'asc')

export default function Client() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [showAdd, setShowAdd] = useState(false);
  const [data, setData] = useState<valuesCrud>([]);
  const [dataEdit, setDataEdit] = useState<valuesCrud>([]);
  const [lastVisible, setlastVisible] = useState<any>();
  const [show, setShow] = useState(false);
  const [dados, setDados] = useState<any>({});

  useEffect(() => {
      setData([]);
      getData();
  }, []);

  async function getData() {
    setLoading(true);
    setError(false);
    const result =
      await dataquery
        .limit(limit)
        .get();

    if (result?.docs?.length) {
      const registro: valuesCrud = [];
      result.docs.forEach((element: any) => {
        const save = { id: element.id, ...element.data() }
        registro.push(save);
      });
      setlastVisible(result.docs[result.docs.length - 1]);
      setData(registro);
    } else {
      setError(true);
      setData([])
    }
    setLoading(false);
  }

  function confirmDelete(id: string){
    confirmAlert({
      title: 'Remover item',
      message: 'Tem certeza que deseja remover este item?',
      overlayClassName: "overlay-custom-danger",
      buttons: [
        {
          label: 'Sim',
          className: 'btn-danger',
          onClick: () => remove(id)
        },
        {
          label: 'Não',
          className: 'btn-primary',
          onClick: () => console.log('cancel')
        }
      ]
    });
  };

  async function remove(uid: string) {
    setLoading(true);
    Firestore.collection('users').doc(uid).delete().then(() => {
      getData();
      confirmAlert({
        title: 'Item removido com sucesso',
        buttons: [{
            label: 'Ok',
            onClick: () => console.log('removido')
          }]
      });
    }).catch((error) => {
      confirmAlert({
        title: 'Erro ao remover item',
        buttons: [
          {
            label: 'Cancelar',
            onClick: () => console.log('removido')
          },
          {
            label: 'Tentar novamente',
            onClick: () => remove(uid)
          }
        ]
      });
    });
    setLoading(false);
  }

  function editOrADD(data: any, edit: boolean){
    setDataEdit(edit ? data : []);
    setShowAdd(true);
  }

  return (
    <section className="page-crud">
      {loading && <MaskLoading />}
        <>
          <div className="card">
            <table className="table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Mais informações</th>
                </tr>
              </thead>
              <tbody>
                {
                  !error ?
                    data.map((val: any) =>
                      <tr>
                        <td>{val.name}</td>
                        <td>{val.email}</td>
                        <td><button type="button" onClick={()=>(setDados(val), setShow(true))}>i</button></td>
                      </tr>
                    )
                    : <tr> <td colSpan={20}> <div className='msg-error'>Erro ao buscar registros</div> </td></tr>
                }
              </tbody>
            </table>

            <Pagination
              limit={limit}
              data={data}
              lastVisible={lastVisible}
              setLastVisible={(val) => setlastVisible(val)}
              dataquery={dataquery}
              newValues={(val) => setData(val)}
              loading={(val) => setLoading(val)}
            />
          </div>
          {show &&
          <div className="background-list">
              <div className="content">
                <h5>Informações sobre o Cliente</h5>
                <ul>
                    <li>
                        <p>Dados do cliente</p>
                        <p></p>
                        <span>Nome: {dados.name} </span>
                        <p></p>
                        <span> CPF: {dados.cpf}</span>
                        <p></p>
                        <span> CNH: {dados.cnh}</span>
                        <p></p>
                        <span> RG: {dados.rg}</span>
                        <p></p>
                        <span> Email: {dados.email}</span>
                        <p></p>
                        <span> Fone: {dados.fone}</span>
                        <p></p>
                        <span> CEP: {dados.cep}</span>
                        <p></p>
                        <span> Endereço: {dados.addressLine1}</span>
                        <p></p>
                        <span> Número: {dados.number}</span>
                        <p></p>
                        <span> Bairro: {dados.neighborhood}</span>
                        <p></p>
                        <span> Cidade: {dados.cityName}</span>
                        <p></p>
                        <span> UF: {dados.stateAbbreviation}</span>
                        <p></p>
                    </li>
                    <li><br/></li>
                </ul>
                <div className="buttom">
                    <button className="close" type="button" onClick={()=>( setShow(false))}>Fechar</button>
                </div>
            </div>
        </div>
    }
        </>
    </section>
  )
}
