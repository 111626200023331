import api from "./api";

const FunctionsService = {
    //id
    refound: async (id : string) => {
        try {
            const res = await api.post("/paymentService/transaction/"+id+"/refound");
            return res;
        } catch (error) {
            return error;
        }
    },

    getPayload: async (data : any) => {
        try {
            const res = await api.post("/paymentService/payload", data);
            return res.data;
        } catch (error) {
            return error;
        }
    },

    balance: async () => {
        try {
            const res = await api.get("/paymentService/balance");
            return res.data;
        } catch (error) {
            return error;
        }
    }
}

export default FunctionsService;