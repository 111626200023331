import React from 'react'

import { useEffect, useState } from 'react';
import MaskLoading from '../../components/maskLoading';
import { Storage, Firestore } from '../../services/firebase';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import 'moment/locale/pt-br';

import EditOffer from './editOffer';

import './style.scss';
import { config } from 'node:process';


interface imgCarrossel {
  url?    : string,
  name?   : string
}
type imgData = offerInteface[];
interface offerInteface {
  id?      : string,
  position : number,
  cityId? : string,
  roteId? : string,
  price? : string,
  from_UF? : string,
  to_UF? : string,
  from_name? : string,
  to_name? : string,
  url? : string
}
type offer = offerInteface[];
interface data {
  last_km: number,
  last_km_at : string,
  last_client: number,
  last_client_at : string,
  who_we? : string,
  average_client : number,
  average_km : number,
  policy? : string,
  terms? : string,
  facebook? : string,
  instagram? : string,
  twitter? : string,
  whatsapp? : string,
  email_sac? : string,
  phone_sac? : string,
  opening_hours? : string,
  icon_legend_1? : string,
  icon_legend_2? : string,
  icon_legend_3? : string,
  link_banner_footer? : string,
  target_banner_footer? : string,
}
const configValues = {
  last_km         : 0,
  last_km_at      : '',
  last_client     : 0,
  last_client_at  : '',
  average_client  : 0,
  average_km      : 0,
  policy          : '',
  terms           : '',
  facebook        : '',
  instagram       : '',
  twitter         : '',
  whatsapp        : '',
  icon_legend_1   : '',
  icon_legend_2   : '',
  icon_legend_3   : '',
  email_sac       : '',
  phone_sac       : '',
  opening_hours   : '',
}

const dataqueryCarrossel = Firestore.collection('image_carroseel');
const dataqueryConfig = Firestore.collection('config');
const dataqueryOffer = Firestore.collection('offers').orderBy('position', 'asc');

export default function ConfigPage() {
  const [loading, setLoading] = useState(true);
  const [loadingImage, setLoadingImage] = useState(false);
  const [data, setData] = useState<data>(configValues);
  const [dataImagens, setDataImages] = useState<imgData>([]);
  const [offers, setOffers] = useState<offer>([]);
  const [dataEdit, setDataEdit] = useState<offer>([]);
  const [lastData, setLastData] = useState<data>(configValues);
  const [id, setID] = useState<string>('');

  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    moment.locale('pt-br');
    setLoading(true);
    getData();
  }, []);

  async function getData() {
    setLoading(true);
    const responseImg = await dataqueryCarrossel.get();
    if( !responseImg.empty ){
      const aImage : imgData = [];
      responseImg.docs.forEach((element: any) => {
        const save = { id: element.id, ...element.data() }
        aImage.push(save);
      });
      setDataImages(aImage);
    } else {
      setDataImages([]);
    }

    const response = await dataqueryConfig.get();
    if( !response.empty ){
      setID(response.docs[0].id);
      let config = {
        last_km         : response.docs[0].data()['last_km'],
        last_km_at      : moment(response.docs[0].data()['last_km_at']).format('DD/MM/YY hh:mm'),
        last_client     : response.docs[0].data()['last_client'],
        last_client_at  : moment(response.docs[0].data()['last_client_at']).format('DD/MM/YY hh:mm'),
        average_km      : response.docs[0].data()['average_km'],
        average_client  : response.docs[0].data()['average_client'],

        who_we          : response.docs[0].data()['who_we'],
        terms           : response.docs[0].data()['terms'],
        policy          : response.docs[0].data()['policy'],

        facebook          : response.docs[0].data()['facebook'],
        instagram           : response.docs[0].data()['instagram'],
        twitter          : response.docs[0].data()['twitter'],
        whatsapp          : response.docs[0].data()['whatsapp'],

        icon_legend_1   : response.docs[0].data()['icon_legend_1'],
        icon_legend_2   : response.docs[0].data()['icon_legend_2'],
        icon_legend_3   : response.docs[0].data()['icon_legend_3'],

        email_sac       : response.docs[0].data()['email_sac'],
        phone_sac       : response.docs[0].data()['phone_sac'],
        opening_hours   : response.docs[0].data()['opening_hours'],
        link_banner_footer   : response.docs[0].data()['link_banner_footer'],
        target_banner_footer   : response.docs[0].data()['target_banner_footer'],
      };
      await setData(config);
      await setLastData(config);
    }
    const responseOffer = await dataqueryOffer.get();
    if( !responseOffer.empty ){
      const array : offer = [];
      responseOffer.docs.forEach((element: any) => {
        const save = { id: element.id, ...element.data() }
        array.push(save);
      });
      setOffers(array);
    }
    setLoading(false);
  }

  async function salvar() {
    if( loading )
      return;

    setLoading(true);

    let config = {
      last_km         : data.last_km,
      last_km_at      : moment(data.last_km_at, "DD/MM/YY hh:mm").toISOString(),
      last_client     : data.last_client,
      last_client_at  : moment(data.last_client_at, "DD/MM/YY hh:mm").toISOString(),
      average_km      : data.average_km,
      average_client  : data.average_client,
      who_we          : data.who_we,
      terms           : data.terms,
      policy          : data.policy,
      facebook        : data.facebook,
      instagram       : data.instagram,
      twitter         : data.twitter,
      whatsapp        : data.whatsapp,
      icon_legend_1   : data.icon_legend_1,
      icon_legend_2   : data.icon_legend_2,
      icon_legend_3   : data.icon_legend_3,
      email_sac       : data.email_sac,
      phone_sac       : data.phone_sac,
      opening_hours   : data.opening_hours,
      link_banner_footer   : data.link_banner_footer,
      target_banner_footer   : data.target_banner_footer,
    };

    if( data.last_km !== lastData.last_km ){
      config.last_km_at = moment().toISOString();
    }
    if( data.last_client !== lastData.last_client ){
      config.last_client_at = moment().toISOString();
    }

    if( config )
      await dataqueryConfig.doc(id).update(config).then(response => {
        getData();
      }).catch(erro => {
        setLoading(false);
        console.log(erro);
      });
  }

  function editOrADD(data: any, edit: boolean){
    setDataEdit(edit ? data : []);
    setShowAdd(true);
  }

  async function upload(field : string, image : any) {

    if(image.length > 0)
      image = image[0];
    else
      return;

    setLoadingImage(true);

    const oldImage = await Storage.ref().child(field+'.jpeg').listAll();
    if( oldImage.items.length > 0 )
      await Storage.ref().child(field+'.jpeg').delete();

    await Storage.ref().child(field+'.jpeg').put(image);
    setLoadingImage(false);
  }

  async function uploadCarrossel(image : any) {
    if(image.length > 0)
      image = image[0];
    else
      return;

    setLoading(true);
    await Storage.ref('carrossel').child(image.name)
    .put(image)
    .then((snap) => {
      snap.ref.getDownloadURL()
      .then((url) => {
        let img = {
          url : url,
          name : image.name
        }
        dataqueryCarrossel.add(img)
        .then((response) => {
          getData();
        });

      });
    })
    .catch(() => {
      setLoading(false);
    })
  }

  async function deleteImage(image : any) {
    setLoading(true);
    Storage.ref('carrossel').child(image.name).delete()
    .then((response) => {

    })
    .catch((error) => {
      setLoading(false);
    });

    dataqueryCarrossel.doc(image.id).delete()
    .then((response) => {
      getData();
    })
    .catch((error) => {
      setLoading(false);
    });
  }

  function getUrl(url:string) {
    if(loadingImage){
      return 'https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/giphy.gif?alt=media'
    } else {
      return url
    }
  }

  return (
    <section className="page-config">
      {loading && <MaskLoading />}
      <div className="card">
        {!showAdd ?
          <div className="hear">
            <h1>Promoções em destaque</h1>
            <hr/>
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Origem</th>
                  <th>Destino</th>
                  <th>Preço</th>
                </tr>
              </thead>
              <tbody>
                {offers.map((val: any) =>
                  <tr key={val.id} onClick={() => editOrADD(val, true)}>
                    <td>{val.position}</td>
                    <td>{val.from_name}</td>
                    <td>{val.to_name}</td>
                    <td>{val.price}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        :
          <EditOffer data={dataEdit} refresh={getData} back={(event: boolean) => setShowAdd(event)} />
        }
      </div>

      <div className="card">
        <h1>Numeros</h1>
        <hr className="divider"/>
        <div className="div-container">
          <div className="div-input">
            <label className="label-input">Clientes Atendidos </label><br/>
            <input
              type="number"
              value={data.last_client}
              onChange={(val) => setData({ ...data, last_client: parseInt(val.target.value) })}
              onBlur={(val) => salvar()}
            /><br/>
            <label className="legend-input">Ataulizado em: {data.last_client_at}</label>
          </div>
          <div className="div-input">
            <label className="label-input">Média de Clientes (mês) </label><br/>
            <input
              type="number"
              value={data.average_client}
              onChange={(val) => setData({ ...data, average_client: parseInt(val.target.value) })}
              onBlur={(val) => salvar()}
            /><br/>
            <label className="legend-input">&nbsp;</label>
          </div>
          <div className="div-input">
            <label className="label-input">KM Rodados</label><br/>
            <input
              type="number"
              value={data.last_km}
              onChange={(val) => setData({ ...data, last_km: parseInt(val.target.value) })}
              onBlur={(val) => salvar()}
            /><br/>
            <label className="legend-input">Ataulizado em: {data.last_km_at}</label>
          </div>
          <div className="div-input">
            <label className="label-input">Média de KM (mês)</label><br/>
            <input
              type="number"
              value={data.average_km}
              onChange={(val) => setData({ ...data, average_km: parseInt(val.target.value) })}
              onBlur={(val) => salvar()}
            /><br/>
            <label className="legend-input">&nbsp;</label>
          </div>
        </div>
      </div>
      <div className="card">
        <h1>Imagens Carrossel (página inicial)</h1>
        <hr className="divider"/>
        <div className="div-container">
          {dataImagens.map((image: any) =>
            <div className="div-input" key={image.id}>
              <div className="deleteButton"><a href="javascript:void(0)" onClick={(val) => deleteImage(image)}>&times;</a></div><br/>
              <img className="miniImg" src={image.url} />

            </div>
          )}
          <div className="div-input">
            <img className="miniImg" src="https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/plus.png?alt=media&token=5d585e3a-314e-46f1-a58c-1bd2a99384af" />
            <input
              type="file"
              onChange={(val) => uploadCarrossel(val.target.files)}
            /><br/>
          </div>
        </div>
      </div>
      <div className="card">
        <h1>Imagens página inicial</h1>
        <hr className="divider"/>
        <div className="div-container">
          <div className="div-input">
            <label className="label-input">Icone (1)</label><br/>
            <img className="miniImg" src={getUrl("https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/banner_url_1.jpeg?alt=media&nada"+Date.now())} />
            <input
              type="file"
              onChange={(val) => upload('banner_url_1', val.target.files)}
            /><br/>
            <input
              type="text"
              value={data.icon_legend_1}
              onChange={(val) => setData({ ...data, icon_legend_1: val.target.value })}
              onBlur={(val) => salvar()}
            /><br/>
          </div>
          <div className="div-input">
            <label className="label-input">Icone (2)</label><br/>
            <img className="miniImg" src={getUrl("https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/banner_url_2.jpeg?alt=media&nada"+Date.now())} />
            <input
              type="file"
              onChange={(val) => upload('banner_url_2', val.target.files)}
            /><br/>
            <input
              type="text"
              value={data.icon_legend_2}
              onChange={(val) => setData({ ...data, icon_legend_2: val.target.value })}
              onBlur={(val) => salvar()}
            /><br/>
          </div>
          <div className="div-input">
            <label className="label-input">Icone (3)</label><br/>
            <img className="miniImg" src={getUrl("https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/banner_url_3.jpeg?alt=media&nada"+Date.now())} />
            <input
              type="file"
              onChange={(val) => upload('banner_url_3', val.target.files)}
            /><br/>
            <input
              type="text"
              value={data.icon_legend_3}
              onChange={(val) => setData({ ...data, icon_legend_3: val.target.value })}
              onBlur={(val) => salvar()}
            /><br/>
          </div>
        </div>
      </div>
      <div className="card">
        <h1>Banner Footer</h1>
        <hr />
        <div className="div-input">
            <label className="label-input">Imagem Desktop</label><br/>
            <img className="miniImg" src={getUrl("https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/footer_url.jpeg?alt=media&nada"+Date.now())} />
            <br/>
            <input
              type="file"
              onChange={(val) => upload('footer_url', val.target.files)}
            /><br/><br/><br/>

            <label className="label-input">Imagem Mobile</label><br/>
            <img className="miniImg" src={getUrl("https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/footer_url_mobile.jpeg?alt=media&nada"+Date.now())} />
            <br/>
            <input
              type="file"
              onChange={(val) => upload('footer_url_mobile', val.target.files)}
            /><br/><br/><br/>
        </div>
        <div className="div-input">
            <label className="label-input">Link </label><br/>
            <input
              type="text"
              value={data.link_banner_footer}
              onChange={(val) => setData({ ...data, link_banner_footer: val.target.value })}
              onBlur={(val) => salvar()}
            /><br/><br/>
        </div>
        <div className="div-input">
            <label className="label-input">Abrir na mesma Janela? </label><br/>
            <select
              value={data.target_banner_footer}
              onChange={(val) => setData({ ...data, target_banner_footer: val.target.value })}
              onBlur={(val) => salvar()}
            >
              <option value="_blank">Outra janela</option>t
              <option value="_self">Mesma janela</option>t
            </select>
        </div>
      </div>
      <div className="card">
        <h1>Imagens de rodapé (página de busca)</h1>
        <hr className="divider"/>
        <div className="div-container">
          <div className="div-input">
            <label className="label-input">Rodapé busca (1)</label><br/>
            <img className="miniImg" src={getUrl("https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/footer_busca_url_1.jpeg?alt=media&nada"+Date.now())} />
            <input
              type="file"
              onChange={(val) => upload('footer_busca_url_1', val.target.files)}
            /><br/>
          </div>
          <div className="div-input">
            <label className="label-input">Rodapé busca (2)</label><br/>
            <img className="miniImg" src={getUrl("https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/footer_busca_url_2.jpeg?alt=media&nada"+Date.now())} />
            <input
              type="file"
              onChange={(val) => upload('footer_busca_url_2', val.target.files)}
            /><br/>
          </div>
          <div className="div-input">
            <label className="label-input">Rodapé busca (3)</label><br/>
            <img className="miniImg" src={getUrl("https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/footer_busca_url_3.jpeg?alt=media&nada"+Date.now())} />
            <input
              type="file"
              onChange={(val) => upload('footer_busca_url_3', val.target.files)}
            /><br/>
          </div>
          <div className="div-input">
            <label className="label-input">Rodapé busca (4)</label><br/>
            <img className="miniImg" src={getUrl("https://firebasestorage.googleapis.com/v0/b/rotas-297b8.appspot.com/o/footer_busca_url_4.jpeg?alt=media&nada"+Date.now())} />
            <input
              type="file"
              onChange={(val) => upload('footer_busca_url_4', val.target.files)}
            /><br/>
          </div>
        </div>
      </div>
      <div className="card">
        <h1>Outras Informações</h1>
        <hr className="divider"/>
        <div className="div-container">
          <div className="div-text">
            <label className="label-input">Quem somos</label><br/>
            <textarea
              value={data.who_we}
              onChange={(val) => setData({ ...data, who_we: val.target.value })}
              onBlur={(val) => salvar()}
            />
          </div>
        </div>
        <div className="div-container">
          <div className="div-text">
            <label className="label-input">Política de Privacidade</label><br/>
            <textarea
              value={data.policy}
              onChange={(val) => setData({ ...data, policy: val.target.value })}
              onBlur={(val) => salvar()}
            />
          </div>
        </div>
        <div className="div-container">
          <div className="div-text">
            <label className="label-input">Termos de uso</label><br/>
            <textarea
              value={data.terms}
              onChange={(val) => setData({ ...data, terms: val.target.value })}
              onBlur={(val) => salvar()}
            />
          </div>
        </div>
      </div>
      <div className="card">
        <h1>Redes sociais</h1>
        <hr className="divider"/>
        <div className="div-container">
          <div className="div-input">
            <label className="label-input">Facebook </label><br/>
            <input
              type="text"
              value={data.facebook}
              onChange={(val) => setData({ ...data, facebook: val.target.value })}
              onBlur={(val) => salvar()}
            /><br/>
          </div>
          <div className="div-input">
            <label className="label-input">Instagram </label><br/>
            <input
              type="text"
              value={data.instagram}
              onChange={(val) => setData({ ...data, instagram: val.target.value })}
              onBlur={(val) => salvar()}
            /><br/>
          </div>
          <div className="div-input">
            <label className="label-input">Twitter </label><br/>
            <input
              type="text"
              value={data.twitter}
              onChange={(val) => setData({ ...data, twitter: val.target.value })}
              onBlur={(val) => salvar()}
            /><br/>
          </div>
          <div className="div-input">
            <label className="label-input">Whatsapp </label><br/>
            <input
              type="text"
              value={data.whatsapp}
              onChange={(val) => setData({ ...data, whatsapp: val.target.value })}
              onBlur={(val) => salvar()}
            /><br/>
          </div>
        </div>

      </div>
      <div className="card">
        <h1>SAC</h1>
        <hr className="divider"/>
        <div className="div-container">
          <div className="div-input">
            <label className="label-input">Email </label><br/>
            <input
              type="text"
              value={data.email_sac}
              onChange={(val) => setData({ ...data, email_sac: val.target.value })}
              onBlur={(val) => salvar()}
            /><br/>
          </div>
          <div className="div-input">
            <label className="label-input">Telefone </label><br/>
            <input
              type="text"
              value={data.phone_sac}
              onChange={(val) => setData({ ...data, phone_sac: val.target.value })}
              onBlur={(val) => salvar()}
            /><br/>
          </div>
          <div className="div-input">
            <label className="label-input">Informações de Atendimento </label><br/>
            <input
              type="text"
              value={data.opening_hours}
              onChange={(val) => setData({ ...data, opening_hours: val.target.value })}
              onBlur={(val) => salvar()}
            /><br/>
          </div>
        </div>
      </div>

    </section>
  )
}
