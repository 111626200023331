import React from 'react'

import { useEffect, useState } from 'react';
import MaskLoading from '../../components/maskLoading';
import Pagination from '../../components/pagination/pagination';
import { Firestore } from '../../services/firebase';
import { confirmAlert } from 'react-confirm-alert';
import FunctionsService from "../../services/functions";
import moment from 'moment';

import './style.scss';

interface dataCrud {
  data?: string,
  cliente?: string,
  rota?: string,
  pagamento?: any
}
type valuesCrud = dataCrud[];

const initalFilter = {
  startDate : moment().startOf('month').format('YYYY-MM-DD'),
  endDate : moment().endOf('month').format('YYYY-MM-DD'),
  type : 'error'
}

const limit = 20;
const queryErrorDefault    = Firestore.collection('purchases_erros').orderBy('date', 'asc');
const querySucceessDefault = Firestore.collection('purchases').orderBy('date', 'asc');


export default function Purchase() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [data, setData] = useState<valuesCrud>([]);
  const [show, setShow] = useState(false);
  const [dados, setDados] = useState<any>({});
  const [filter, setFilter] = useState<any>(initalFilter);
  const [total, setTotal] = useState('0.00');

  const [queryError, setQueryError] = useState<any>(queryErrorDefault);
  const [querySucceess, setQuerySucceess] = useState<any>(querySucceessDefault);

  const [lastVisible, setlastVisible] = useState<any>();

  useEffect(() => {
    let tqueryError = queryError
    .where('date', '>', moment(filter.startDate).toDate())
    .where('date', '<', moment(filter.endDate).toDate());
    setQueryError(tqueryError);

    let tquerySucceess = querySucceess
    .where('date', '>', moment(filter.startDate).toDate())
    .where('date', '<', moment(filter.endDate).toDate());
    setQueryError(tquerySucceess);

    setData([]);
    getData();
  }, []);

  async function getData() {
    setLoading(true);
    setError(false);
    let result:any;
    if( filter.type == 'error'){
      result =
        await queryError
        .where('date', '>', moment(filter.startDate).toDate())
        .where('date', '<', moment(filter.endDate).toDate())
        .limit(limit)
        .get();
    }
    if( filter.type == 'success'){
      result =
        await querySucceess
        .where('date', '>', moment(filter.startDate).toDate())
        .where('date', '<', moment(filter.endDate).toDate())
        .limit(limit)
        .get();
    }
    if (result?.docs?.length) {
      const registro: valuesCrud = [];
      result.docs.forEach((element: any) => {
        const save = { id: element.id, ...element.data() }
        registro.push(save);
      });
      setlastVisible(result.docs[0]);
      setData(registro);
      getTotals();
    } else {
      setError(true);
      setData([]);
      setTotal('0.00');
    }
    setLoading(false);
  }

  async function getTotals(){
    if( filter.type == 'success'){
      let result =
        await querySucceess
        .where('date', '>', moment(filter.startDate).toDate())
        .where('date', '<', moment(filter.endDate).toDate())
        .get();
      if (result?.docs?.length) {
        let total = 0;
        result.docs.forEach((element: any) => {
          const save = { id: element.id, ...element.data() }
          if( save.pagamento.status && save.pagamento.status == 'refound')
            total -= (save.pagamento.transaction ? save.pagamento.transaction.amount : 0)
          else
            total += (save.pagamento.transaction ? save.pagamento.transaction.amount : 0)
        });
        setTotal((total/100).toFixed(2));
      } else {
        setTotal('0.00');
      }

    } else {
      setTotal('0.00');
    }
  }

  function confirmRefound(purchase: any){
    confirmAlert({
      title: 'Estornar transação',
      message: 'Tem certeza que deseja estornar esta transação?',
      overlayClassName: "overlay-custom-danger",
      buttons: [
        {
          label: 'Sim',
          className: 'btn-danger',
          onClick: () => refound(purchase)
        },
        {
          label: 'Não',
          className: 'btn-primary',
          onClick: () => console.log('cancel')
        }
      ]
    });
  };

  function refound(purchase: any) {
    setLoading(true);
    FunctionsService.refound(purchase.pagamento.transaction_id)
    .then(() => {
      purchase.pagamento.status = 'refound';
      Firestore.collection('purchases').doc(purchase.id).update(purchase)
      .then(() => {
        setData([]);
        getData();
      })
      .catch(() => {
        setLoading(false);
      });
    })
    .catch(() => {
      setLoading(false);
    });
  }

  function showPurchase(purchase: any) {
    console.log(purchase);
    setDados(purchase);
    setShow(true);
  }

  useEffect(() => {
    let tqueryError = queryError
    .where('date', '>', moment(filter.startDate).toDate())
    .where('date', '<', moment(filter.endDate).toDate());
    setQueryError(tqueryError);

    let tquerySucceess = querySucceess
    .where('date', '>', moment(filter.startDate).toDate())
    .where('date', '<', moment(filter.endDate).toDate());
    setQueryError(tquerySucceess);

    setData([]);
    getData();
  }, [filter]);

  return (
    <section className="page-crud">
    {loading && <MaskLoading />}
        <>
          <div className="card">
            <h1>Total</h1>
            <div className="form formGroup-Block-2">
              <div className="formGroup formGroup-Block-2">
                {/* <label>Total</label> */}
                <input type="number" value={total}  disabled />
              </div>
            </div>
          </div>
          <div className="card">
            <h1>Vendas</h1>
            <div className="form formGroup-Block-4">
              <div className="formGroup formGroup-Block-4">
                <label>De</label>
                <input type="date" value={filter.startDate}  onChange={(val) => setFilter({ ...filter, startDate: val.target.value})} />
              </div>
              <div className="formGroup formGroup-Block-4">
                <label>Até</label>
                <input type="date" value={filter.endDate}  onChange={(val) => setFilter({ ...filter, endDate: val.target.value})} />
              </div>
              <div className="formGroup formGroup-Block-4">
                <label>Tipo</label>
                <select value={filter.type} onChange={(val) => setFilter({ ...filter, type: val.target.value})}>
                  <option value="error">Erro</option>
                  <option value="success">Sucesso</option>
                </select>
              </div>
              <div className="formGroup formGroup-Block-4">
                <label>&nbsp;</label>
              </div>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Data</th>
                  <th>Cliente</th>
                  <th>Rota</th>
                  {filter.type == 'success' ? <>
                    <th>Valor</th>
                    <th>Transação</th>
                  </> :
                    <th>Erro</th>
                  }
                  <th>Informações</th>
                </tr>
              </thead>
              <tbody>
              {
                !error ?
                  data.map((val: any) =>
                      <tr>
                          <td>
                            {filter.type == 'success' ?
                             val.pagamento.status && val.pagamento.status == 'refound' ? 'Estornado' : 'Pago'
                            :
                              'Erro'
                            }
                          </td>
                          <td>{new Date(val.date.seconds * 1000).toLocaleDateString("pt-br")}</td>
                          <td>{val.user.name}</td>
                          <td>{val.viagem[0].origem.cidade} - {val.viagem[0].destino.cidade}</td>
                          {filter.type == 'success' ? <>
                            <td>R$ {val.pagamento.transaction ? (val.pagamento.transaction.amount / 100).toFixed(2) : ''}</td>
                            <td>{val.pagamento.transaction_id}</td>
                          </> :
                            <td>{val.status}</td>
                          }
                          <td>
                            <button type="button" onClick={()=>(showPurchase(val))}>i</button>
                            { filter.type == 'success' && (!val.pagamento.status || val.pagamento.status != 'refound') ? <button className="btn-danger" type="button" onClick={ () => confirmRefound(val)}>x</button> : '' }
                          </td>
                      </tr>
                  )
                  : <tr> <td colSpan={20}> <div className='msg-error'>Erro ao buscar registros</div> </td></tr>
              }
              </tbody>
            </table>
            <Pagination
              limit={limit}
              data={data}
              lastVisible={lastVisible}
              setLastVisible={(val) => setlastVisible(val)}
              dataquery={filter.type == 'error' ? queryError : querySucceess}
              newValues={(val) => setData(val)}
              loading={(val) => setLoading(val)}
            />
          </div>
          {show &&
          <div className="background-list">
              <div className="content">
                <h5>Informações sobre o erro</h5>
                <ul>
                    <li>
                        <p>Dados do cliente</p>
                        <span>Nome: {dados.user.name} </span>
                        <p></p>
                        <span> CPF: {dados.user.cpf}</span>
                        <p></p>
                        <span> Email: {dados.user.email}</span>
                        <p></p>
                        <span> Fone: {dados.user.fone}</span>
                    </li>
                    <li><br/></li>
                    <li>
                        <p>Dados do pagamento</p>
                        <span>Código: {dados.pagamento.code}</span>
                        <p></p>
                        <span>Mesangem: {dados.pagamento.message}</span>
                        <p></p>
                    </li>
                    <li><br/></li>
                    { dados.viagem.map((viagem: any) =>
                      <>
                        <li>
                            <p>Dados da passagem</p>
                            <span>Origem: {viagem?.origem?.cidade}</span>
                            <p></p>
                            <span> Destino: {viagem?.destino?.cidade}</span>
                            <p></p>
                            <span> Data:  {viagem?.data}</span>
                            <p></p>
                        </li>
                        <li><br/></li>
                        <li>
                            <p>Dados da Viação</p>
                            <span>Empresa:  {viagem?.bus?.empresa}</span>
                            <p></p>
                            <span> Classe:  {viagem?.bus?.classe}</span>
                            <p></p>
                            <span> Cnpj:  {viagem?.bus?.cnpj}</span>
                            <p></p>
                        </li>
                        <li><br/></li>
                      </>
                    )}
                    <li>
                        <p>Dados dos assentos</p>
                        {
                          dados.assentosIda.map((assento: any) =>
                            <>
                              <span>Assento ida Nº: {assento.number}</span>
                              <p></p>
                            </>
                          )
                        }
                        <p></p>
                        {
                          dados.assentosVolta.map((assento: any) =>
                            <>
                              <span>Assento ida Nº: {assento.number}</span>
                              <p></p>
                            </>
                          )
                        }
                        <p></p>
                    </li>
                    <li><br/></li>
                    { filter.type != 'success' &&
                      <li>
                        <p>Dados do erro:</p>
                        <span>{
                            dados.status == 'Erro ao efetuar pagamento' ?
                                (dados.pagamento.errors && dados.pagamento.errors.length > 0 ?
                                    dados.pagamento.errors[0].message
                                :
                                  <>
                                  { dados.pagamento.transaction.status_reason == 'antifraud' && <span className="span-error">Antifraude, alto risco</span> }
                                  { dados.pagamento.transaction.status_reason == 'acquirer' && <span className="span-error">Cartão negado, entrar em contato com a operadora</span> }
                                  </>
                                )
                            :
                                (dados.assentos) }</span>
                        <p></p>
                      </li>
                    }
                </ul>
                <div className="buttom">
                    <button className="close" type="button" onClick={()=>( setShow(false))}>Fechar</button>
                </div>
            </div>
        </div>
    }
        </>
    </section>
  )
}
