import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyCXoceWwcUbC1oBSMkJV7JSCpw0TE8gHJo",
  authDomain: "rotas-297b8.firebaseapp.com",
  projectId: "rotas-297b8",
  storageBucket: "rotas-297b8.appspot.com",
  messagingSenderId: "579649582886",
  appId: "1:579649582886:web:386214a6264b711d1273e7",
  measurementId: "G-LFR582JSZN"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth;
export const Firestore = firebase.firestore();
export const Storage = firebase.storage();
